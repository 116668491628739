import React, { useState, memo, useMemo } from "react";
import "./index.css";

export const Test = () => {
  const first = async () => {
    console.log("1");

    const user = {
      email: "lviv08@gmail.com",
      password: "henry1414",
      browser_fingerprint: "mkdasnakladgnadlgfgs",
    };

    // const res = await fetch("https://order24-api.utr.ua/api/login_check", {
    //   method: "POST",

    //   headers: { "Content-Type": "application/json" },

    //   body: JSON.stringify(user),
    // });

    // const bodyToken = await res.json();

    // console.log(bodyToken);

    fetch("https://order24-api.utr.ua/api/login_check", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data1) => {
        console.log(data1);
      });

    console.log("3");
  };

  return (
    <button
      onClick={() => {
        first();
      }}
      className="test"
    >
      hello
    </button>
  );
};

export { Test as default } from "./testing_Vel.js";

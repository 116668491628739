import { async } from '@firebase/util'
import React, { createContext, useState } from 'react'
import { PRODUCTS } from '../Products'

export const FetchContext = createContext(null)

export const FetchContextProvider = props => {
  const [loadingSpiner, setLoadingSpiner] = useState(false)
  const [loadingAple, setLoadingAple] = useState(false)
  const [brakes, setBrakes] = useState([])
  const [loadingCategoty, setLoadingCategory] = useState(false)
  const [loadingEngine, setLoadingEngine] = useState(false)

  const getFromStock = article => {
    setLoadingSpiner(true)
    const productInStock = PRODUCTS.find(product => product.article === article)
    if (typeof productInStock == 'undefined') {
      setLoadingSpiner(false)
      return null
    } else setLoadingSpiner(false)
    return productInStock
  }

  const fetchUnicTrade = async article => {
    setLoadingSpiner(true)

    const user = {
      email: 'lviv08@gmail.com',
      password: 'henry1414',
      browser_fingerprint: 'mkdasnakladgnadlgfgs',
    }

    const res = await fetch('https://order24-api.utr.ua/api/login_check', {
      method: 'POST',

      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify(user),
    })

    const bodyToken = await res.json()

    const res2 = await fetch(
      `https://order24-api.utr.ua/api/search/${article}`,
      {
        method: 'GET',

        headers: { Authorization: 'Bearer ' + bodyToken.token },
      }
    )

    const bodyNullImages = await res2.json()

    if (
      typeof bodyNullImages?.details[0] == 'undefined' ||
      typeof bodyNullImages.details[0].remains[0] == 'undefined'
    ) {
      return null
    } else {
      const res3 = await fetch(
        `https://order24-api.utr.ua/api/detail/${bodyNullImages?.details[0].id}`,
        {
          method: 'GET',

          headers: { Authorization: 'Bearer ' + bodyToken.token },
        }
      )

      const body3 = await res3.json()

      console.log(body3)

      const storeLviv = body3.remains.find(product => product.storage.id === 37)

      console.log(storeLviv)

      if (typeof storeLviv == 'undefined') {
        const storeKiyv = body3.remains.find(
          product => product.storage.id === 15
        )

        if (typeof storeKiyv == 'undefined') {
          const dataUTR = {
            title: body3.title,
            price: body3.yourPrice.amount,
            img: body3.images[0]?.fullImagePath,
            article: body3.article,
            inStockOther: body3.remains[0].remain,
            brandName: body3.brand.name,
          }

          setLoadingSpiner(false)

          return dataUTR
        } else {
          const dataUTR = {
            title: body3.title,
            price: body3.yourPrice.amount,
            img: body3.images[0]?.fullImagePath,
            article: body3.article,
            inStockKiyv: storeKiyv.remain,
            brandName: body3.brand.name,
          }

          setLoadingSpiner(false)

          return dataUTR
        }
      } else {
        const dataUTR = {
          title: body3.title,
          price: body3.yourPrice.amount,
          img: body3.images[0]?.fullImagePath,
          article: body3.article,
          inStock: storeLviv.remain,
          brandName: body3.brand.name,
        }

        setLoadingSpiner(false)

        return dataUTR
      }
    }
  }

  const fetchTM = async (article, brandID = null) => {
    if (brandID === null) {
      setLoadingSpiner(true)

      const user1 = {
        apiToken: process.env.REACT_APP_TokenTM,
        code: article,
      }

      const currency = 'UAH'

      const user3 = {
        apiToken: process.env.REACT_APP_TokenTM,
        brandId: 0,
        code: article,
        isShowAnalogs: 0,
        currency: currency,
      }

      const res = await fetch(
        'https://api.tehnomir.com.ua/info/getBrandsByCode',
        {
          method: 'POST',

          headers: { 'Content-Type': 'application/json' },

          body: JSON.stringify(user1),
        }
      )

      const body = await res.json()

      console.log(body.data[0])

      if (body.data.length > 1) {
        setLoadingSpiner(false)
        return body
      } else if (typeof body.data[0] == 'undefined') {
        setLoadingSpiner(false)
        return null
      } else {
        const user2 = {
          apiToken: process.env.REACT_APP_TokenTM,
          brandId: `${body.data[0].brandId}`,
          code: article,
        }

        const res2 = await fetch(
          'https://api.tehnomir.com.ua/info/getProductInfo',
          {
            method: 'POST',

            headers: { 'Content-Type': 'application/json' },

            body: JSON.stringify(user2),
          }
        )

        const info = await res2.json()

        console.log(info)

        if (typeof info.data.images[0] == 'undefined') {
          info.data.images[0] =
            'https://as2.ftcdn.net/v2/jpg/04/00/24/31/1000_F_400243185_BOxON3h9avMUX10RsDkt3pJ8iQx72kS3.jpg'
        }

        const res3 = await fetch('https://api.tehnomir.com.ua/price/search', {
          method: 'POST',

          headers: { 'Content-Type': 'application/json' },

          body: JSON.stringify(user3),
        })

        const price1 = await res3.json()

        console.log(price1)

        const min = Math.min.apply(
          Math,
          price1.data[0].rests.map(function (o) {
            return o.deliveryTime
          })
        )

        const isLargeNumber = element => element.deliveryTime === min

        const index1 = price1.data[0].rests.findIndex(isLargeNumber)

        const dataTM = {
          title: info.data.descriptionRus,
          img: info.data?.images[0].image,
          price: price1.data[0].rests[index1].price,
          article: price1.data[0].code,
          deliveryTime: price1.data[0].rests[index1].deliveryTime,
          brandName: info.data.brand,
        }

        setLoadingSpiner(false)

        return dataTM
      }
    } else {
      fetchTMChoosenBrand(article, brandID)
    }
  }

  const fetchTMChoosenBrand = async (article, brandID) => {
    console.log(brandID, article)

    const currency = 'UAH'

    const user2 = {
      apiToken: process.env.REACT_APP_TokenTM,
      brandId: brandID,
      code: article,
    }

    const user3 = {
      apiToken: process.env.REACT_APP_TokenTM,
      brandId: brandID,
      code: article,
      isShowAnalogs: 0,
      currency: currency,
    }

    const res2 = await fetch(
      'https://api.tehnomir.com.ua/info/getProductInfo',
      {
        method: 'POST',

        headers: { 'Content-Type': 'application/json' },

        body: JSON.stringify(user2),
      }
    )

    const info = await res2.json()

    console.log(info)

    if (typeof info.data.images[0] == 'undefined') {
      info.data.images[0] =
        'https://as2.ftcdn.net/v2/jpg/04/00/24/31/1000_F_400243185_BOxON3h9avMUX10RsDkt3pJ8iQx72kS3.jpg'
    }

    const res3 = await fetch('https://api.tehnomir.com.ua/price/search', {
      method: 'POST',

      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify(user3),
    })

    const price1 = await res3.json()

    console.log(price1)

    const min = Math.min.apply(
      Math,
      price1.data[0].rests.map(function (o) {
        return o.deliveryTime
      })
    )

    const isLargeNumber = element => element.deliveryTime === min

    const index1 = price1.data[0].rests.findIndex(isLargeNumber)

    const dataTM = {
      title: info.data.descriptionRus,
      img: info.data?.images[0].image,
      price: price1.data[0].rests[index1].price * 1.2,
      article: price1.data[0].code,
      deliveryTime: price1.data[0].rests[index1].deliveryTime,
      brandName: info.data.brand,
    }

    setLoadingSpiner(false)

    return dataTM
  }

  const fetchUsingModels = async article => {
    const user = {
      email: 'lviv08@gmail.com',
      password: 'henry1414',
      browser_fingerprint: 'mkdasnakladgnadlgfgs',
    }

    setLoadingAple(true)

    const res = await fetch('https://order24-api.utr.ua/api/login_check', {
      method: 'POST',

      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify(user),
    })

    const bodyToken = await res.json()

    const res2 = await fetch(
      `https://order24-api.utr.ua/api/search/${article}`,
      {
        method: 'GET',

        headers: { Authorization: 'Bearer ' + bodyToken.token },
      }
    )

    const bodyNullImages = await res2.json()

    if (typeof bodyNullImages.details[0] == 'undefined') {
      setLoadingAple(false)
      return null
    } else {
      const res3 = await fetch(
        `https://order24-api.utr.ua/api/applicability/${bodyNullImages?.details[0].id}`,
        {
          method: 'GET',

          headers: { Authorization: 'Bearer ' + bodyToken.token },
        }
      )

      const dataAplicable = await res3.json()
      setLoadingAple(false)
      return dataAplicable
    }
  }

  const myServerUTR = async article => {
    setLoadingSpiner(true)

    const data = {
      article1: article,
    }

    const res = await fetch(
      `https://api.edetal.store/partsUTR?article1=${encodeURIComponent(
        data.article1
      )}`,
      {
        method: 'GET',
      }
    )

    const body = await res.json()

    if (body === null) {
      return null
    } else {
      const storeLviv = body.remains.find(product => product.storage.id === 37)

      console.log(storeLviv)

      if (typeof storeLviv == 'undefined') {
        const storeKiyv = body.remains.find(
          product => product.storage.id === 15
        )

        if (typeof storeKiyv == 'undefined') {
          const dataUTR = {
            title: body.title,
            price: body.yourPrice.amount,
            img: body.images[0]?.fullImagePath,
            article: body.article,
            inStockOther: body.remains[0].remain,
            brandName: body.brand.name,
          }

          setLoadingSpiner(false)

          return dataUTR
        } else {
          const dataUTR = {
            title: body.title,
            price: body.yourPrice.amount,
            img: body.images[0]?.fullImagePath,
            article: body.article,
            inStockKiyv: storeKiyv.remain,
            brandName: body.brand.name,
          }

          setLoadingSpiner(false)

          return dataUTR
        }
      } else {
        const dataUTR = {
          title: body.title,
          price: body.yourPrice.amount,
          img: body.images[0]?.fullImagePath,
          article: body.article,
          inStock: storeLviv.remain,
          brandName: body.brand.name,
        }

        setLoadingSpiner(false)

        return dataUTR
      }
    }
  }

  const myServerTM = async article => {
    setLoadingSpiner(true)

    const data = {
      article1: article,
    }

    const res1 = await fetch(
      `https://api.edetal.store/partsTM1?article1=${encodeURIComponent(
        data.article1
      )}`,
      {
        method: 'GET',
      }
    )

    const brandID = await res1.json()

    if (brandID === null) {
      setLoadingSpiner(false)
      return null
    } else if (brandID.data.length > 1) {
      setLoadingSpiner(false)
      return brandID
    } else {
      const BiD = brandID.data[0].brandId

      const res2 = await fetch(
        `https://api.edetal.store/partsTM2?article1=${encodeURIComponent(
          data.article1
        )}&brandID1=${BiD}`,
        {
          method: 'GET',
        }
      )

      const info = await res2.json()
      console.log(info)

      const res3 = await fetch(
        `https://api.edetal.store/partsTM3?article1=${encodeURIComponent(
          data.article1
        )}&brandID1=${BiD}`,
        {
          method: 'GET',
        }
      )

      const price1 = await res3.json()

      const min = Math.min.apply(
        Math,
        price1.data[0].rests.map(function (o) {
          return o.deliveryTime
        })
      )

      const isLargeNumber = element => element.deliveryTime === min

      const index1 = price1.data[0].rests.findIndex(isLargeNumber)

      console.log(info)

      if (typeof info.data?.images[0] == 'undefined') {
        const defaultImage = {
          image:
            'https://as2.ftcdn.net/v2/jpg/04/00/24/31/1000_F_400243185_BOxON3h9avMUX10RsDkt3pJ8iQx72kS3.jpg',
        }
        info.data.images.push(defaultImage)
      }

      const dataTM = {
        title: info.data.descriptionRus,
        img: info.data?.images[0].image,
        price: price1.data[0].rests[index1].price,
        article: price1.data[0].code,
        deliveryTime: price1.data[0].rests[index1].deliveryTime,
        brandName: info.data.brand,
      }

      setLoadingSpiner(false)

      return dataTM
    }
  }

  const myServerTMWithBrandID = async (article, brandID) => {
    setLoadingSpiner(true)

    const data = {
      article1: article,
      brandId: brandID,
    }

    {
      const res2 = await fetch(
        `https://api.edetal.store/partsTM2?article1=${encodeURIComponent(
          data.article1
        )}&brandID1=${brandID}`,
        {
          method: 'GET',
        }
      )

      const info = await res2.json()
      console.log(info)

      const res3 = await fetch(
        `https://api.edetal.store/partsTM3?article1=${encodeURIComponent(
          data.article1
        )}&brandID1=${brandID}`,
        {
          method: 'GET',
        }
      )

      const price1 = await res3.json()

      const min = Math.min.apply(
        Math,
        price1.data[0].rests.map(function (o) {
          return o.deliveryTime
        })
      )

      const isLargeNumber = element => element.deliveryTime === min

      const index1 = price1.data[0].rests.findIndex(isLargeNumber)

      console.log(info)

      if (typeof info.data?.images[0] == 'undefined') {
        const defaultImage = {
          image:
            'https://as2.ftcdn.net/v2/jpg/04/00/24/31/1000_F_400243185_BOxON3h9avMUX10RsDkt3pJ8iQx72kS3.jpg',
        }
        info.data.images.push(defaultImage)
      }

      const dataTM = {
        title: info.data.descriptionRus,
        img: info.data?.images[0].image,
        price: price1.data[0].rests[index1].price,
        article: price1.data[0].code,
        deliveryTime: price1.data[0].rests[index1].deliveryTime,
        brandName: info.data.brand,
      }

      setLoadingSpiner(false)

      return dataTM
    }
  }

  const testUTRUsingModels = async article => {
    setLoadingAple(true)

    const data = {
      article1: article,
    }

    const res = await fetch(
      `https://api.edetal.store/partsUTRUsingModels?article1=${encodeURIComponent(
        data.article1
      )}`,
      {
        method: 'GET',
      }
    )

    const usingModels = await res.json()
    setLoadingAple(false)
    return usingModels
  }

  const testUTRAnalogs = async article => {
    setLoadingAple(true)

    const data = {
      article1: article,
    }

    const res = await fetch(
      `https://api.edetal.store/partsUTRAnalogs?article1=${encodeURIComponent(
        data.article1
      )}`,
      {
        method: 'GET',
      }
    )

    const analogs = await res.json()
    setLoadingAple(false)
    console.log(analogs)
    return analogs
  }

  const fetchBrakesCategory = async productArr => {
    setLoadingCategory(true)
    setBrakes([])

    await Promise.all(
      productArr.map(brake => {
        const data = {
          id: brake.id,
        }

        return fetch(
          `https://api.edetal.store/partsById?id=${encodeURIComponent(
            data.id
          )}`,
          {
            method: 'GET',
          }
        ).then(res => res.json())
      })
    ).then(body => {
      setBrakes(body)
      setLoadingCategory(false)
    })
  }

  const meServerBM = async article => {
    setLoadingSpiner(true)

    try {
      const data = {
        article1: article,
      }

      const res = await fetch(
        `https://api.edetal.store/bmparts?article1=${encodeURIComponent(
          data.article1
        )}`,
        {
          method: 'GET',
        }
      )

      const body = await res.json()
      const body1 = body.products
      const array = Object.values(body.products)
      setLoadingSpiner(false)

      return array
    } catch (error) {
      setLoadingSpiner(false)
      alert('Помилка, спробуйте ще раз')
    }
  }

  const meServerBMGetModels = async car => {
    const data = {
      car: car,
    }

    const res = await fetch(
      `https://api.edetal.store/getModels?car=${encodeURIComponent(data.car)}`,
      {
        method: 'GET',
      }
    )

    const body = await res.json()

    return body
  }

  const meServerBMGetEnginesByModels = async (car, model) => {
    setLoadingEngine(true)

    const data = {
      car: car,
      model: model,
    }

    const res = await fetch(
      `https://api.edetal.store/getEnginesByModels?car=${encodeURIComponent(
        data.car
      )}&model=${encodeURIComponent(data.model)}`,
      {
        method: 'GET',
      }
    )

    const body = await res.json()

    setLoadingEngine(false)

    return body
  }

  const contextValue = {
    FetchContextProvider,
    fetchUnicTrade,
    fetchTM,
    loadingSpiner,
    fetchUsingModels,
    loadingAple,
    fetchTMChoosenBrand,
    getFromStock,
    myServerUTR,
    myServerTM,
    testUTRUsingModels,
    testUTRAnalogs,
    myServerTMWithBrandID,
    fetchBrakesCategory,
    brakes,
    loadingCategoty,
    meServerBM,
    meServerBMGetModels,
    meServerBMGetEnginesByModels,
    loadingEngine,
  }

  return (
    <FetchContext.Provider value={contextValue}>
      {props.children}
    </FetchContext.Provider>
  )
}

/*const currency = "UAH";

        const user2 = {
            apiToken: "jLiA0DZd9LwG6K75xXpfMoZX8t3L7SsO",
            brandId: brandID ,
            code: "25182957",
        }

        const user3 = {
            apiToken: "jLiA0DZd9LwG6K75xXpfMoZX8t3L7SsO",
            brandId: 0,
            code: "25182957",
            isShowAnalogs: 0,
            currency: currency,
          };

    const res2 = await fetch("https://api.tehnomir.com.ua/info/getProductInfo", {
            method: "POST",
  
            headers: { "Content-Type": "application/json" },
  
            body: JSON.stringify(user2),
          })

        const  info = await res2.json();


    const res3 =  await  fetch("https://api.tehnomir.com.ua/price/search", {
            method: "POST",

            headers: { "Content-Type": "application/json" },

            body: JSON.stringify(user3),
          })

          const price1 = await res3.json();

          const dataTM = {
            title : info.data.descriptionRus,
            img : info.data?.images[0].image,
            price : price1.data[0].rests[0].price,
            article : price1.data[0].code
          }

          setLoadingSpiner(false)

          return dataTM;*/

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { useState } from "react";
import { Navbar } from "./Components/navbar";
import { ShopContextProvider } from "./context/shop-context";
import { FetchContextProvider } from "./context/fetch-context";
import { PaginationContextProvider } from "./context/pagination-context";
import { Footer } from "./Components/footer";
import { ThankyouRequest } from "./Pages/ThankyouRequest/Thankyou";
import { LoadingSpinner } from "./Components/spiner";
import { Test } from "./testing/testing_Vel";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-257034260-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  const [loadingPage, setLoadingPage] = useState(true);
  const preloader1 = document.getElementById("preloader");
  if (preloader1) {
    setTimeout(() => {
      preloader1.style.display = "none";
      setLoadingPage(false);
    }, 1000);
  }

  const BrandMain = React.lazy(() => import("./Pages/BrandMain/newmain"));
  const UsaParts = React.lazy(() => import("./Pages/UsaParts/usaparts"));
  const Search = React.lazy(() => import("./Pages/Search/Search"));
  const Cart = React.lazy(() => import("./Pages/Cart/cart"));
  const Thankyou = React.lazy(() => import("./Pages/Thankyou/Thankyou"));
  const AboutUs = React.lazy(() => import("./Pages/About/About"));
  const Vinrequest = React.lazy(() => import("./Pages/Vinrequest/vinrequest"));
  const NewSearch = React.lazy(() =>
    import("./Pages/SearchWithNewCSS/newsearch")
  );
  /*const ThankyouRequest = React.lazy(() =>
    import("./Pages/ThankyouRequest/Thankyou")
  );*/
  const SearchWithModels = React.lazy(() =>
    import("./Pages/SearchWithModels/Search")
  );

  return (
    !loadingPage && (
      <div className="root1">
        <PaginationContextProvider>
          <FetchContextProvider>
            <ShopContextProvider>
              <Router>
                <Navbar />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <BrandMain />
                      </React.Suspense>
                    }
                  />
                  <Route path="/test" element={<Test />} />
                  <Route
                    path="/newsearch"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <NewSearch />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/usarepair"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <UsaParts />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/search"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <Search />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/cart"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <Cart />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/thankyou"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <Thankyou />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/aboutus"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <AboutUs />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/vinrequest"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <Vinrequest />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/thankyourequest"
                    element={<ThankyouRequest />}
                  />
                  <Route
                    path="/SearchWithModels"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">
                            Завантаження <LoadingSpinner />
                          </div>
                        }
                      >
                        <SearchWithModels />
                      </React.Suspense>
                    }
                  />
                </Routes>
                <Footer />
              </Router>
            </ShopContextProvider>
          </FetchContextProvider>
        </PaginationContextProvider>
      </div>
    )
  );
}

export default App;

/*useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);*/

//<Route path="/" element={<BrandMain />}></Route>

/*<Route 
                    path="/"
                    element={
                      <React.Suspense fallback={<>...</>}>
                        <BrandMain />
                      </React.Suspense>
                    }
                  />
                  
                  <Route
                    path="/thankyourequest"
                    element={
                      <React.Suspense
                        fallback={
                          <div className="blackScreen">Завантаження...</div>
                        }
                      >
                        <ThankyouRequest />
                      </React.Suspense>
                    }
                  />*/

import React, { createContext, useEffect, useState } from "react";
import { collection, getDocs} from "firebase/firestore";
import { db } from "../base";

import { PRODUCTS } from "../Products";

export const PaginationContext = createContext(null)


export const PaginationContextProvider = (props) => {

    const itemsOnPage = 9;

    const allTotalPages = Math.ceil(PRODUCTS.length/itemsOnPage);
    
    const [totalPages, setTotalPages] = useState (allTotalPages)
    const [activePage, setActivePage] = useState(0)
    const [start, setStart] = useState (0)
    const [end , setEnd] = useState (itemsOnPage)
    const [itemsInShop , setItemsInShop] = useState(PRODUCTS.slice(start, end))

    const changePage = (index) => {
        setActivePage(index);
        setItemsInShop(PRODUCTS.slice((index*itemsOnPage), (index*itemsOnPage+itemsOnPage)))
    }


    const [orders , setOrders] = useState([]);
    const [seaches, setSearchs] = useState([]);
    const ordersCollectionRef = collection(db, "Orders");
    const searchesCollectionRef = collection(db, "Searches")


    const  getOrders = async () => {
            const data = await getDocs(ordersCollectionRef)
            setOrders(data.docs.map((doc)=>({...doc.data(), id: doc.id})))
            console.log(orders)
        };

        const  getSearches = async () => {
            const data = await getDocs(searchesCollectionRef)
            setSearchs(data.docs.map((doc)=>({...doc.data(), id: doc.id})))
            console.log(seaches)
        };

    

       

    

    const contextValue = { itemsInShop , activePage , totalPages , changePage , getOrders , orders, getSearches, seaches }

    return  (
            <PaginationContext.Provider value={contextValue}>
                {props.children}
            </PaginationContext.Provider>
    )
    
}
import React, { createContext, useState } from "react";
import { addDoc, collection} from "firebase/firestore";
import { db } from "../base";
import { useEffect } from "react";

export const ShopContext = createContext(null)


export const ShopContextProvider = (props) => {

    let defaultcartItems = JSON.parse(localStorage.getItem("cartItems"));
  if (defaultcartItems === null) {
    defaultcartItems = [];
  } else {
    defaultcartItems = JSON.parse(localStorage.getItem("cartItems"));
  }
  let defaulitemsNumber = JSON.parse(localStorage.getItem("itemsNumber"));
  if (defaulitemsNumber === null) {
    defaulitemsNumber = 0;
  } else {
    defaulitemsNumber = JSON.parse(localStorage.getItem("itemsNumber"));
  }
  let defaultcartsItemsObj = JSON.parse(localStorage.getItem("cartsItemsObj"));
  if (defaultcartsItemsObj === null) {
    defaultcartsItemsObj = {};
  } else {
    defaultcartsItemsObj = JSON.parse(localStorage.getItem("cartsItemsObj"));
  }
    
    const [cartItems, setCartItems] = useState (defaultcartItems)
    const [itemsNumber, setItemsNumber] = useState(defaulitemsNumber)
    const [cartsItemsObj, setCartsItemsObj] =useState (defaultcartsItemsObj)
    const [brand, setBrand] = useState("Оберіть бренд");
    const [chooseBrand, setChoosenBrand] = useState(false);
    const [itemBig, setItemBig] = useState(null);
    const [addLabel, setAddLabel] = useState({status :false, title : null});
    const [openQuickCart, setOpenQuickCart] = useState(false);
    const [openQuickItem, setOpenQuickItem] = useState(false);



      

    

    const ordersRef = collection(db, "Orders");
    const requestRef = collection(db, "Requests");
    const articletRef = collection(db, "Searches");
    const requestAfterSearchRef = collection(db, "RequestAfterSearch");

    const sendData = async (data1, data2, data3, data4, data5, data6, data7) => {
        await addDoc(ordersRef, {
            Amount : data1,
            Products : data2,
            Deliver : data3,
            ClientNumber : data4,
            Price: data5,
            date:data6,
            time:data7
        })

        setCartsItemsObj ({});
        setItemsNumber (null);
        setCartItems ([]);


        
    }

    const sendDataFromRequest = async (data1, data2, data3) => {
        await addDoc(requestRef, {
            VIN : data1,
            Products : data2,
            ClientNumber : data3,
        }) 
    }

    const sendDataArticle = async (data1, data2, data3) => {
        await addDoc(articletRef, {
            article : data1,
            date : data2,
            time : data3
        }) 
    }

    const sendDataAfterSearch = async (data1, data2, data3) => {
        await addDoc(requestAfterSearchRef, {
            article : data1,
            tel : data2,
            vin:data3
        }) 
    }

    function createObj (item)  {
        console.log(cartsItemsObj[item.article])
        if (typeof cartsItemsObj[item.article] == "number") {cartsItemsObj[item.article] = cartsItemsObj[item.article] +1; 
            setCartsItemsObj(cartsItemsObj)
            localStorage.setItem('cartsItemsObj', JSON.stringify(cartsItemsObj))} else
        {cartsItemsObj[item.article]=1 
        setCartsItemsObj(cartsItemsObj)
        localStorage.setItem('cartsItemsObj', JSON.stringify(cartsItemsObj))}
    }

    function changeStatusAdd () {
        setAddLabel({status:false})
    }

    const addToCart = (item) => {
        fetch(`https://api.telegram.org/bot6173056848:AAE0eviFsiQtx0CWxEJyBizEdl_zhaJ0P1w/sendMessage?chat_id=@edetal&text=Увага! До кошика додано новий товар : ${item.article + " " + item.title}`)
        setAddLabel({status:true, title : item.title})
        setTimeout(changeStatusAdd, 4000);

        
        if (cartItems.find(product => product.article === item.article)) 
        {setItemsNumber(itemsNumber+1)
            createObj(item)
            localStorage.setItem('itemsNumber', JSON.stringify(itemsNumber+1))
            ;} else 
        {cartItems.push(item);
        setCartItems(cartItems)
        setItemsNumber(itemsNumber+1)
        createObj(item);
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
        localStorage.setItem('itemsNumber', JSON.stringify(itemsNumber+1))
        }
    }

    const removeFromBusket = (article) => {

        setItemsNumber(itemsNumber-cartsItemsObj[article])
        localStorage.setItem('itemsNumber', JSON.stringify(itemsNumber-cartsItemsObj[article]))
        delete (cartsItemsObj[article]);
        setCartsItemsObj(cartsItemsObj);
        localStorage.setItem('cartsItemsObj', JSON.stringify(cartsItemsObj))

        const objWithIdIndex = cartItems.findIndex((obj) => obj.article === article);

        if (objWithIdIndex > -1) {
        cartItems.splice(objWithIdIndex, 1);
        setCartItems(cartItems)
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
        
        
  }

  return cartItems;
    }

    const removeOneUnit = (article) => {
        
        if (cartsItemsObj[article] === 1) {
            const objWithIdIndex = cartItems.findIndex((obj) => obj.article === article);

            if (objWithIdIndex > -1) {
            cartItems.splice(objWithIdIndex, 1);
            setCartItems(cartItems);
            setItemsNumber(itemsNumber - 1);
            localStorage.setItem('cartItems', JSON.stringify(cartItems))
            localStorage.setItem('itemsNumber', JSON.stringify(itemsNumber-1))
            
        
            
            
      } else {return}} else
        setItemsNumber(itemsNumber - 1);
        localStorage.setItem('itemsNumber', JSON.stringify(itemsNumber-1))
        cartsItemsObj[article] = cartsItemsObj[article] -1
    }

    const contextValue = { cartItems , addToCart , itemsNumber , removeFromBusket , cartsItemsObj, removeOneUnit, sendData, sendDataFromRequest, sendDataArticle, sendDataAfterSearch, setCartItems, setItemsNumber, setCartsItemsObj, brand, setBrand, itemBig, setItemBig, addLabel, openQuickCart, setOpenQuickCart, openQuickItem, setOpenQuickItem, chooseBrand, setChoosenBrand}

    return  (
            <ShopContext.Provider value={contextValue}>
                {props.children}
            </ShopContext.Provider>
    )
    
}
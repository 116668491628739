import React from "react";
import './Thankyou.css'
import { useNavigate } from "react-router-dom";



export const ThankyouRequest = () => {

    const navigate = useNavigate()

    const goToShop = () => {
        navigate("/");
        
    }


    return (
    <div className="thankYouPage">
        <h1 className="thankkYouTitle">
            Ми вже обробляємо Вашу заявку! 
            Найближчим часом зв'яжемось з Вами
        </h1>
            <div>
                <button className="backToShopBTN" onClick={()=>goToShop()}> На головну </button>
            </div>
            <img src="https://edetal.store/media/thankyou.jpg" />
    </div>
    )
    
}

export { ThankyouRequest as default } from "./Thankyou.jsx";
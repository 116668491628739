export const PRODUCTS = [
  {
    id: 2,
    title: "Обгінна муфта",
    article: "535022710",
    price: 1500,
    img: "https://static.wixstatic.com/media/1dd549_8725f4a45f854f4190b8b66f0e58e3f1~mv2.jpg/v1/fill/w_1064,h_1064,al_c,q_85,usm_0.66_1.00_0.01/1dd549_8725f4a45f854f4190b8b66f0e58e3f1~mv2.jpg",
    inStock: 0,
    brandName: "INA",
  },
  {
    id: 13,
    title: "Олива моторна 5W30 Professional A5",
    article: "15D5E8",
    price: 1850,
    img: "https://static.wixstatic.com/media/1dd549_c912fe4b26664192894dca33f62cf20f~mv2.webp",
    inStock: 1,
    brandName: "Castrol",
  },
  {
    id: 3,
    title: "Масляний фільтр",
    article: "OX351D",
    price: 365,
    img: "https://static.wixstatic.com/media/1dd549_9fd8159379d643c9a385ccd5983ab0e9~mv2.jpg/v1/fill/w_1064,h_1064,al_c,q_85,usm_0.66_1.00_0.01/1dd549_9fd8159379d643c9a385ccd5983ab0e9~mv2.jpg",
    inStock: 0,
    brandName: "Mahle",
  },
  {
    id: 14,
    title: "Паливний насос",
    article: "DMP020076",
    price: 3955,
    img: "https://static.wixstatic.com/media/1dd549_96e23922a31d4f4bb7bf4d521bb92945~mv2.webp",
    inStock: 1,
    brandName: "Mando",
  },
  {
    id: 13,
    title: "Гальмівна рідина DOT-4",
    article: "0882380111",
    price: 295,
    img: "https://static.wixstatic.com/media/1dd549_9c6dcae165534fe9a91b5e74d3da6dc6~mv2.webp",
    inStock: 0,
    brandName: "Toyota",
  },

  {
    id: 15,
    title: "Задній ліхтар VW Passat",
    article: "FP7439F2E",
    price: 4499,
    img: "https://static.wixstatic.com/media/1dd549_c2866337aa294d5089a6ae5f965daeee~mv2.webp",
    inStock: 0,
    brandName: "DEPO",
  },
  {
    id: 16,
    title: "Накладка решітки радіатора",
    article: "86360K4AA0",
    price: 4800,
    img: "https://static.wixstatic.com/media/1dd549_efc52ac4bb604147a7ea59365d4a8c79~mv2.jpg/v1/fill/w_912,h_1064,al_c,q_85,usm_0.66_1.00_0.01/1dd549_efc52ac4bb604147a7ea59365d4a8c79~mv2.jpg",
    inStock: 1,
    brandName: "Hyundai/KIA",
  },
  {
    id: 5,
    title: "Ремкомплект пильника зовнішнього ШРУСа",
    article: "1004980047",
    price: 390,
    img: "https://static.wixstatic.com/media/1dd549_33cd4757b85c4eb9b5b6957e4173b4be~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_33cd4757b85c4eb9b5b6957e4173b4be~mv2.webp",
    inStock: 0,
    brandName: "Meyle",
  },
  {
    id: 4,
    title: "Датчик положення АКПП",
    article: "427002N700",
    price: 3999,
    img: "https://static.wixstatic.com/media/1dd549_092f3136fd7f4cc68e8fa3ac3818dc1e~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_092f3136fd7f4cc68e8fa3ac3818dc1e~mv2.webp",
    inStock: 1,
    brandName: "Hyundai/KIA",
  },
  {
    id: 6,
    title: "Олива трансмісійна",
    article: "199474",
    price: 499,
    img: "https://static.wixstatic.com/media/1dd549_fc95a60c78d349bb8ecaf43135f6a352~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_fc95a60c78d349bb8ecaf43135f6a352~mv2.webp",
    inStock: 0,
    brandName: "Total",
  },
  {
    id: 7,
    title: "Свічки запалювання",
    article: "ZFR5F",
    price: 180,
    img: "https://static.wixstatic.com/media/1dd549_b7e9d55699b4453687c4dac61d21f99c~mv2.webp",
    inStock: 0,
    brandName: "NGK",
  },
  {
    id: 1,
    title: "Кронштейн решітки",
    article: "5311210090",
    price: 4999,
    img: "https://static.wixstatic.com/media/1dd549_be750480b8664869981d415fbe5fd969~mv2.jpg/v1/fill/w_474,h_532,al_c,q_85,usm_0.66_1.00_0.01/1dd549_be750480b8664869981d415fbe5fd969~mv2.jpg",
    inStock: 0,
    brandName: "Toyota",
  },
  {
    id: 8,
    title: "Ролик навісного обладнання",
    article: "T36765",
    price: 719.1,
    img: "https://static.wixstatic.com/media/1dd549_97a48453f425400bae0f99069e383fe3~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_97a48453f425400bae0f99069e383fe3~mv2.webp",
    inStock: 1,
    brandName: "Gates",
  },
  {
    id: 9,
    title: "Центральний кронштейн",
    article: "5NN805705F",
    price: 1290,
    img: "https://static.wixstatic.com/media/1dd549_84f9a55c68224b96a0a2d685f4afb4f5~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_84f9a55c68224b96a0a2d685f4afb4f5~mv2.webp",
    inStock: 0,
    brandName: "VW",
  },
  {
    id: 10,
    title: "Кермова тяга",
    article: "JAR1022",
    price: 599,
    img: "https://static.wixstatic.com/media/1dd549_60257b50a5724e3da94d0ab4d8f2f648~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_60257b50a5724e3da94d0ab4d8f2f648~mv2.webp",
    inStock: 0,
    brandName: "TRW",
  },
  {
    id: 11,
    title: "Шарнірний комплект",
    article: "899037",
    price: 999,
    img: "https://static.wixstatic.com/media/1dd549_e589bd25677840b1a9eb3bf5911aab2a~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_e589bd25677840b1a9eb3bf5911aab2a~mv2.webp",
    inStock: 0,
    brandName: "GSP",
  },
  {
    id: 12,
    title: "Підшипник ступиці",
    article: "753329",
    price: 850,
    img: "https://static.wixstatic.com/media/1dd549_b36e439a8c774798a051b7919645e746~mv2.jpg/v1/fill/w_688,h_688,al_c,q_85,usm_0.66_1.00_0.01/1dd549_b36e439a8c774798a051b7919645e746~mv2.webp",
    inStock: 0,
    brandName: "NK",
  },
  {
    id: 17,
    title: "Фара ліва до Mercedes Sprinter",
    article: "A9109060400",
    price: 14150,
    img: "https://static.wixstatic.com/media/1dd549_62052073b3b446c9b690b230b2b3e680~mv2.jpg/v1/fill/w_688,h_917,al_c,q_85,usm_0.66_1.00_0.01/1dd549_62052073b3b446c9b690b230b2b3e680~mv2.webp",
    inStock: 0,
    brandName: "Mercedes Benz",
  },
];

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDaU7sRcR_9080mihQiKsTAXHJdNqqviTg",
  authDomain: "bayrakparts.firebaseapp.com",
  projectId: "bayrakparts",
  storageBucket: "bayrakparts.appspot.com",
  messagingSenderId: "368085754164",
  appId: "1:368085754164:web:1fc46767854985637e0561",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
